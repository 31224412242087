import {useState} from 'react'
import {NumericFormat} from 'react-number-format'
import {Formik} from 'formik'
import PDFDocument from './PDFDocument'
import {PDFDownloadLink} from '@react-pdf/renderer'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'

const EXCHANGE_RATE = 90

export const vessels = [
  {
    id: 1,
    name: 'FESCO Trader (Balios)',
    containerCapacity: 1060,
    grt: 12471,
    nrt: 5336,
    dwt: 15213,
    teu: 1060,
    class: 'A',
    speed: '17,5',
    fuelConsumption: '50,3',
    flag: 'Panama',
    year: 1997,
    length: '147',
    draught: '8,5',
    breadth: 25
  },
  {
    id: 2,
    name: 'Captain Artyukh',
    containerCapacity: 490,
    grt: 9141,
    nrt: 3974,
    dwt: 9548,
    teu: 490,
    class: 'L2',
    speed: '17,8',
    fuelConsumption: '37,8',
    flag: 'Panama',
    year: 1986,
    length: '148,66',
    draught: '7,65',
    breadth: 21
  }
]

const App = () => {
  const [freightRate, setFreightRate] = useState(null),
    [freightPerTeu, setFreightPerTeu] = useState(null),
    [dollarFreightRate, setDollarFreightRate] = useState(null),
    [demurrageRate, setDemurageRate] = useState(null),
    [showFreightRate, setShowFreightRate] = useState(false),
    [brokerageCommission, setBrokerageCommission] = useState(null),
    [dollarBrokerageCommission, setDollarBrokerageCommission] = useState(null),
    [isDocumentReady, setIsDocumentReady] = useState(false),

    calculateFreightRate = values => {
      let freightRate,
        percent = 1
      switch (values.vesselId) {
        case 1:
          freightRate = 145575815
          break
        case 2:
          freightRate = 49181384
          break
        default:
          break
      }
      if (values.prepaymentType === '0') {
        percent -= 0.05
      }
      if (values.prepaymentAmount === '30') {
        percent -= 0.015
      } else if (values.prepaymentAmount === '50') {
        percent -= 0.03
      }

      if (values.thirdPartyCargo === 'MOLOO') {
        percent += 0.015
      }

      freightRate = Math.floor(freightRate * percent)

      setBrokerageCommission(Math.floor(freightRate * 0.085))
      setDollarBrokerageCommission(Math.floor(Math.floor(freightRate * 0.085) / EXCHANGE_RATE))

      setDemurageRate(Math.floor(freightRate * 0.025 / EXCHANGE_RATE))

      if (values.loadingUploadingCost === 'shipowner') {
        if (['filo', 'lifo'].includes(values.loadingUploadingCostShipOwnerType)) {
          freightRate += (values.vesselId === 1 ? 265000 : 122500) * EXCHANGE_RATE
        } else {
          freightRate += (values.vesselId === 1 ? 530000 : 245000) * EXCHANGE_RATE
        }
      }

      setFreightPerTeu(Math.floor(freightRate / vessels[values.vesselId - 1].teu / EXCHANGE_RATE))
      setDollarFreightRate(Math.floor(freightRate / EXCHANGE_RATE))

      setFreightRate(freightRate)
    }

  return (
    <div className="App">
      <div className="container pt-3 pb-3">
        <h1 className="text-center mb-5">Расчёт</h1>
        <Formik
          initialValues={{
            shipbroker: '',
            placeAndDate: '',
            ownersAndPlace: '',
            charterersAndPlace: '',
            vesselId: null,
            vesselInsuranceCost: '',
            loadingPort: null,
            deliveryPlace: '',
            returnPlace: '',
            layDayTime: '',
            layTime: '',
            layTimeType: null,
            layTimeSubType: null,
            cancellingDate: '',
            agentsOfLoading: '',
            agentsOfDischarge: '',
            portOfDischarge: null,
            insuranceShipownersClubName: '',
            insuranceCharterersClubName: '',
            commonAccidentRules: null,
            lawAndArbitration: '',
            additionalClauses:  null,
            paymentType: null,
            prepaymentType: null,
            prepaymentAmount: null,
            loadingUploadingCost: null,
            notice: [],
            deviation: null,
            compensation: null,
            deckTransportation: null,
            thirdPartyCargo: null,
            nonStandardizedContainer: null,
            portOption: null,
          }}
          validate={values => {
            const errors = {}
            const errorMsg = 'Заполните поле'
            if (!values.shipbroker) {
              errors.shipbroker = errorMsg
            }
            if (!values.placeAndDate) {
              errors.placeAndDate = errorMsg
            }
            if (!values.ownersAndPlace) {
              errors.ownersAndPlace = errorMsg
            }
            if (!values.charterersAndPlace) {
              errors.charterersAndPlace = errorMsg
            }
            if (!values.vesselId) {
              errors.vesselId = 'Выберите судно'
            }
            if (!values.vesselInsuranceCost) {
              errors.vesselInsuranceCost = errorMsg
            }
            if (!values.loadingPort) {
              errors.loadingPort = errorMsg
            }
            if (!values.deliveryPlace) {
              errors.deliveryPlace = errorMsg
            }
            if (!values.returnPlace) {
              errors.returnPlace = errorMsg
            }
            if (!values.layDayTime) {
              errors.layDayTime = errorMsg
            }
            if (!values.layTime) {
              errors.layTime = errorMsg
            }
            if (!values.layTimeType) {
              errors.layTimeType = errorMsg
            }
            if (values.layTimeType && !values.layTimeSubType) {
              errors.layTimeSubType = errorMsg
            }
            if (!values.cancellingDate) {
              errors.cancellingDate = errorMsg
            }
            if (!values.agentsOfLoading) {
              errors.agentsOfLoading = errorMsg
            }
            if (!values.agentsOfDischarge) {
              errors.agentsOfDischarge = errorMsg
            }
            if (!values.portOfDischarge) {
              errors.portOfDischarge = errorMsg
            }
            if (!values.insuranceShipownersClubName) {
              errors.insuranceShipownersClubName = errorMsg
            }
            if (!values.insuranceCharterersClubName) {
              errors.insuranceCharterersClubName = errorMsg
            }
            if (!values.commonAccidentRules) {
              errors.commonAccidentRules = errorMsg
            }
            if (!values.lawAndArbitration) {
              errors.lawAndArbitration = errorMsg
            }
            if (!values.additionalClauses) {
              errors.additionalClauses = errorMsg
            }
            if (!values.paymentType) {
              errors.paymentType = errorMsg
            }
            if (values.paymentType === 'cash' && values.prepaymentType === null) {
              errors.prepaymentType = errorMsg
            }
            if (values.prepaymentType === '1' && values.prepaymentAmount === null) {
              errors.prepaymentAmount = errorMsg
            }
            if (!values.loadingUploadingCost) {
              errors.loadingUploadingCost = errorMsg
            }
            if (values.loadingUploadingCost === 'shipowner' && !values.loadingUploadingCostShipOwnerType) {
              errors.loadingUploadingCostShipOwnerType = errorMsg
            }
            if (values.notice.length === 0) {
              errors.notice = 'Выберите одно из значений'
            }
            if (!values.deviation) {
              errors.deviation = errorMsg
            }
            if (!values.compensation) {
              errors.compensation = errorMsg
            }
            if (!values.deckTransportation) {
              errors.deckTransportation = errorMsg
            }
            if (!values.thirdPartyCargo) {
              errors.thirdPartyCargo = errorMsg
            }
            if (!values.nonStandardizedContainer) {
              errors.nonStandardizedContainer = errorMsg
            }
            if (!values.portOption) {
              errors.portOption = errorMsg
            }
            if (isDocumentReady) {
              setIsDocumentReady(false)
            }
            if (showFreightRate) {
              setShowFreightRate(false)
            }
            if (freightRate) {
              setFreightRate(null)
            }
            if (dollarFreightRate) {
              setDollarFreightRate(null)
            }
            if (brokerageCommission) {
              setBrokerageCommission(null)
            }
            if (dollarBrokerageCommission) {
              setDollarBrokerageCommission(null)
            }
            if (demurrageRate) {
              setDemurageRate(null)
            }
            if (freightPerTeu) {
              setFreightPerTeu(null)
            }

            return errors
          }}
            onSubmit={values => {
              calculateFreightRate(values)
              setShowFreightRate(true)
            }}>
          {({ values,
              errors,
              touched,
              handleBlur,
              handleChange,
              setFieldValue,
              submitForm
          }) => (
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="shipbroker" className="form-label">Наименование брокера</label>
                  <input
                    type="text"
                    value={values.shipbroker}
                    onChange={handleChange}
                    name="shipbroker"
                    onBlur={handleBlur}
                    className="form-control"
                    id="shipbroker"/>
                  {(errors.shipbroker && touched.shipbroker) && (
                    <div className="text-danger fs-6">{errors.shipbroker}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="placeAndDate" className="form-label">Место и дата заключения договора</label>
                  <input
                    type="text"
                    name="placeAndDate"
                    value={values.placeAndDate}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="form-control"
                    id="placeAndDate"/>
                  {(errors.placeAndDate && touched.placeAndDate) && (
                    <div className="text-danger fs-6">{errors.placeAndDate}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="ownersAndPlace" className="form-label">Владельцы и местонахождение конторы, телефон,
                    телекс, номер факса</label>
                  <input
                    type="text"
                    name="ownersAndPlace"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.ownersAndPlace}
                    className="form-control"
                    id="ownersAndPlace"/>
                  {(errors.ownersAndPlace && touched.ownersAndPlace) && (
                    <div className="text-danger fs-6">{errors.ownersAndPlace}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="charterersAndPlace" className="form-label">
                    Фрахтователи и местонахождение конторы, телефон, телекс, номер факса
                  </label>
                  <input
                    type="text"
                    name="charterersAndPlace"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.charterersAndPlace}
                    className="form-control"
                    id="charterersAndPlace"/>
                  {(errors.charterersAndPlace && touched.charterersAndPlace) && (
                    <div className="text-danger fs-6">{errors.charterersAndPlace}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="vesselId" className="form-label">Судно</label>
                  <select
                    value={values.vesselId}
                    name="vesselId"
                    onChange={e => setFieldValue('vesselId', parseInt(e.target.value))}
                    onBlur={handleBlur}
                    className="form-select"
                    id="vesselId">
                    <option disabled selected>Выберите судно</option>
                    {vessels.map((vessel) => (
                      <option value={vessel.id} key={vessel.id}>
                        {vessel.name}, {vessel.containerCapacity} TEU
                      </option>
                    ))}
                  </select>
                  {(errors.vesselId && touched.vesselId) && (
                    <div className="text-danger fs-6">{errors.vesselId}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="form-label">Способ оплаты</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="paymentType"
                      id="cashless"
                      value="cashless"
                      onBlur={handleBlur}
                      checked={values.paymentType === 'cashless'}
                      onChange={async e => {
                        await setFieldValue('prepaymentType', null)
                        await setFieldValue('prepaymentAmount', null)
                        handleChange(e)
                      }}/>
                    <label className="form-check-label" htmlFor="cashless">100% оплата по завершению рейса</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="paymentType"
                      id="cash"
                      value="cash"
                      onBlur={handleBlur}
                      checked={values.paymentType === 'cash'}
                      onChange={async e => {
                        await setFieldValue('prepaymentType', null)
                        await setFieldValue('prepaymentAmount', null)
                        handleChange(e)
                      }}/>
                    <label className="form-check-label" htmlFor="cash">Перед началом рейса</label>
                  </div>
                  {(errors.paymentType && touched.paymentType) && (
                    <div className="text-danger fs-6">{errors.paymentType}</div>
                  )}
                  {values.paymentType === 'cash' && (
                    <div className="mx-3">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          onBlur={handleBlur}
                          name="prepaymentType"
                          id="withoutPrepayment"
                          value="0"
                          checked={values.prepaymentType === '0'}
                          onChange={async e => {
                            await setFieldValue('prepaymentAmount', null)
                            handleChange(e)
                          }}/>
                        <label className="form-check-label" htmlFor="withoutPrepayment">100% предоплата</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          onBlur={handleBlur}
                          name="prepaymentType"
                          id="withPrepayment"
                          value="1"
                          checked={values.prepaymentType === '1'}
                          onChange={async e => {
                            await setFieldValue('prepaymentAmount', null)
                            handleChange(e)
                          }}/>
                        <label className="form-check-label" htmlFor="withPrepayment">С авансом</label>
                      </div>
                      {values.prepaymentType === '1' && (
                        <div className="mx-3">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              checked={values.prepaymentAmount === '30'}
                              name="prepaymentAmount"
                              onBlur={handleBlur}
                              id="prepaymentAmount30"
                              value="30"
                              onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="prepaymentAmount30">Предоплата 30%</label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              checked={values.prepaymentAmount === '50'}
                              name="prepaymentAmount"
                              onBlur={handleBlur}
                              id="prepaymentAmount50"
                              value="50"
                              onChange={handleChange}/>
                            <label className="form-check-label" htmlFor="prepaymentAmount50">Предоплата 50%</label>
                          </div>
                          {(errors.prepaymentAmount && touched.prepaymentAmount) && (
                            <div className="text-danger fs-6">{errors.prepaymentAmount}</div>
                          )}
                        </div>
                      )}
                      {(errors.prepaymentType && touched.prepaymentType) && (
                        <div className="text-danger fs-6">{errors.prepaymentType}</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="vesselInsuranceCost" className="form-label">Страховая стоимость судна</label>
                  <input
                    type="text"
                    name="vesselInsuranceCost"
                    value={values.vesselInsuranceCost}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className="form-control"
                    id="vesselInsuranceCost"/>
                  {(errors.vesselInsuranceCost && touched.vesselInsuranceCost) && (
                    <div className="text-danger fs-6">{errors.vesselInsuranceCost}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6"></div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="loadingPort" className="form-label">Порт погрузки</label>
                  <select
                    value={values.loadingPort}
                    name="loadingPort"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-select"
                    id="loadingPort">
                    <option disabled selected>Выберите порт</option>
                    <option value="Japan (Yokohama)">Япония (Йокогама)</option>
                  </select>
                  {(errors.loadingPort && touched.loadingPort) && (
                    <div className="text-danger fs-6">{errors.loadingPort}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="portOfDischarge" className="form-label">Порт выгрузки</label>
                  <select
                    value={values.portOfDischarge}
                    name="portOfDischarge"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-select"
                    id="portOfDischarge">
                    <option disabled selected>Выберите порт</option>
                    <option value="Vladivostok">Владивосток</option>
                  </select>
                  {(errors.portOfDischarge && touched.portOfDischarge) && (
                    <div className="text-danger fs-6">{errors.portOfDischarge}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="agentsOfLoading" className="form-label">Агенты в порту погрузки</label>
                  <input
                    type="text"
                    name="agentsOfLoading"
                    value={values.agentsOfLoading}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    id="agentsOfLoading"/>
                  {(errors.agentsOfLoading && touched.agentsOfLoading) && (
                    <div className="text-danger fs-6">{errors.agentsOfLoading}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="agentsOfDischarge" className="form-label">Агенты в порту выгрузки</label>
                  <input
                    type="text"
                    name="agentsOfDischarge"
                    value={values.agentsOfDischarge}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    id="agentsOfDischarge"/>
                  {(errors.agentsOfDischarge && touched.agentsOfDischarge) && (
                    <div className="text-danger fs-6">{errors.agentsOfDischarge}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="deliveryPlace" className="form-label">Место сдачи</label>
                  <input
                    type="text"
                    name="deliveryPlace"
                    value={values.deliveryPlace}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    id="deliveryPlace"/>
                  {(errors.deliveryPlace && touched.deliveryPlace) && (
                    <div className="text-danger fs-6">{errors.deliveryPlace}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="returnPlace" className="form-label">Место возврата</label>
                  <input
                    type="text"
                    name="returnPlace"
                    value={values.returnPlace}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    id="returnPlace"/>
                  {(errors.returnPlace && touched.returnPlace) && (
                    <div className="text-danger fs-6">{errors.returnPlace}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="layDayTime" className="form-label">Самая ранняя дата сдачи (местное время)</label>
                  <input
                    type="datetime-local"
                    name="layDayTime"
                    value={values.layDayTime}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    id="layDayTime"/>
                  {(errors.layDayTime && touched.layDayTime) && (
                    <div className="text-danger fs-6">{errors.layDayTime}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="layTime" className="form-label">Сталийное время</label>
                  <input
                    type="text"
                    name="layTime"
                    value={values.layTime}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    id="layTime"/>
                  {(errors.layTime && touched.layTime) && (
                    <div className="text-danger fs-6">{errors.layTime}</div>
                  )}
                  <div className="mx-3 mt-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="layTimeType"
                        onBlur={handleBlur}
                        checked={values.layTimeType === 'a'}
                        id="laytimeTypeA"
                        value="a"
                        onChange={async e => {
                          await setFieldValue('layTimeSubType', null)
                          handleChange(e)
                        }}/>
                      <label className="form-check-label" htmlFor="laytimeTypeA">а) Раздельно на погрузку</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="layTimeType"
                        onBlur={handleBlur}
                        checked={values.layTimeType === 'b'}
                        id="laytimeTypeB"
                        value="b"
                        onChange={async e => {
                          await setFieldValue('layTimeSubType', null)
                          handleChange(e)
                        }}/>
                      <label className="form-check-label" htmlFor="laytimeTypeB">b) Раздельно на выгрузку</label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="layTimeType"
                        onBlur={handleBlur}
                        checked={values.layTimeType === 'c'}
                        id="laytimeTypeC"
                        value="c"
                        onChange={async e => {
                          await setFieldValue('layTimeSubType', null)
                          handleChange(e)
                        }}/>
                      <label className="form-check-label" htmlFor="laytimeTypeC">c) Общее на погрузочно-разгрузочные
                        работы</label>
                    </div>
                    {(errors.layTimeType && touched.layTimeType) && (
                      <div className="text-danger fs-6">{errors.layTimeType}</div>
                    )}
                  </div>
                  {values.layTimeType && (
                    <div className="mx-5 mt-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="layTimeSubType"
                          onBlur={handleBlur}
                          checked={values.layTimeSubType === 'wwww'}
                          onChange={handleChange}
                          id="wwww"
                          value="wwww"/>
                        <label className="form-check-label" htmlFor="wwww">WWWW</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="layTimeSubType"
                          id="shex"
                          value="shex"
                          checked={values.layTimeSubType === 'shex'}
                          onBlur={handleBlur}
                          onChange={handleChange}/>
                        <label className="form-check-label" htmlFor="shex">SHEX</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="layTimeSubType"
                          id="shinc"
                          value="shinc"
                          checked={values.layTimeSubType === 'shinc'}
                          onBlur={handleBlur}
                          onChange={handleChange}/>
                        <label className="form-check-label" htmlFor="shinc">SHINC</label>
                      </div>
                      {(errors.layTimeSubType && touched.layTimeSubType) && (
                        <div className="text-danger fs-6">{errors.layTimeSubType}</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="cancellingDate" className="form-label">Дата канцеллинга</label>
                  <input
                    type="datetime-local"
                    name="cancellingDate"
                    value={values.cancellingDate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    id="cancellingDate"/>
                  {(errors.cancellingDate && touched.cancellingDate) && (
                    <div className="text-danger fs-6">{errors.cancellingDate}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6"></div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="insuranceShipownersClubName" className="form-label">Наименование страхового клуба
                    судовладельцев</label>
                  <input
                    type="text"
                    name="insuranceShipownersClubName"
                    value={values.insuranceShipownersClubName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    id="insuranceShipownersClubName"/>
                  {(errors.insuranceShipownersClubName && touched.insuranceShipownersClubName) && (
                    <div className="text-danger fs-6">{errors.insuranceShipownersClubName}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="insuranceCharterersClubName" className="form-label">Наименование страхового клуба<br/>
                    фрахтователей</label>
                  <input
                    type="text"
                    name="insuranceCharterersClubName"
                    value={values.insuranceCharterersClubName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    id="insuranceCharterersClubName"/>
                  {(errors.insuranceCharterersClubName && touched.insuranceCharterersClubName) && (
                    <div className="text-danger fs-6">{errors.insuranceCharterersClubName}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="commonAccidentRules" className="form-label">Общая авария регулируется в соответствии
                    с</label>
                  <select
                    value={values.commonAccidentRules}
                    name="commonAccidentRules"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-select"
                    id="commonAccidentRules">
                    <option disabled selected>Выберите правила</option>
                    <option value="London rules">Лондонские правила</option>
                    <option value="York-Antwerp rules">Йорк-Антверпенские правила</option>
                  </select>
                  {(errors.commonAccidentRules && touched.commonAccidentRules) && (
                    <div className="text-danger fs-6">{errors.commonAccidentRules}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="lawAndArbitration" className="form-label">Право и арбитраж</label>
                  <input
                    type="text"
                    name="lawAndArbitration"
                    value={values.lawAndArbitration}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    id="lawAndArbitration"/>
                  {(errors.lawAndArbitration && touched.lawAndArbitration) && (
                    <div className="text-danger fs-6">{errors.lawAndArbitration}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="additionalClauses" className="form-label">Дополнительные статьи, охватывающие особые
                    положения (указать число)</label>
                  <select
                    value={values.additionalClauses}
                    name="additionalClauses"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-select"
                    id="additionalClauses">
                    <option disabled selected>Выберите значени</option>
                    <option value="8">8</option>
                  </select>
                  {(errors.additionalClauses && touched.additionalClauses) && (
                    <div className="text-danger fs-6">{errors.additionalClauses}</div>
                  )}
                </div>
              </div>
              <div className="col-12 mt-3 text-center">
                <button type="button" className="btn btn-success" onClick={submitForm}>Рассчитать</button>
                {(showFreightRate) && (
                  <div>
                    {(freightRate && dollarFreightRate) && (
                      <div className="mt-3">
                        Ставка фрахта: <NumericFormat displayType="text" suffix=" $" thousandSeparator="&nbsp;"
                                                      value={dollarFreightRate}/> (<NumericFormat displayType="text"
                                                                                                  suffix=" ₽"
                                                                                                  thousandSeparator="&nbsp;"
                                                                                                  value={freightRate}/>)
                      </div>
                    )}
                    {(brokerageCommission && dollarFreightRate) && (
                      <div className="mt-1">
                        Брокерская комиссия: <NumericFormat displayType="text" suffix=" $" thousandSeparator="&nbsp;"
                                                            value={dollarBrokerageCommission}/> (<NumericFormat
                        displayType="text" suffix=" ₽" thousandSeparator="&nbsp;" value={brokerageCommission}/>)
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="col-12 mt-5 text-center">
                <h3>Аддендум</h3>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="form-label">Оплата погрузочно-разгрузочных работ</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={values.loadingUploadingCost === 'charterer'}
                      name="loadingUploadingCost"
                      id="charterer"
                      value="charterer"
                      onBlur={handleBlur}
                      onChange={async e => {
                        await setFieldValue('loadingUploadingCostShipOwnerType', null)
                        handleChange(e)
                      }}/>
                    <label className="form-check-label" htmlFor="charterer">За счет фрахтователя</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      checked={values.loadingUploadingCost === 'shipowner'}
                      name="loadingUploadingCost"
                      id="shipowner"
                      value="shipowner"
                      onBlur={handleBlur}
                      onChange={async e => {
                        await setFieldValue('loadingUploadingCostShipOwnerType', null)
                        handleChange(e)
                      }}/>
                    <label className="form-check-label" htmlFor="shipowner">За счет судовладельца</label>
                  </div>
                  {(errors.loadingUploadingCost && touched.loadingUploadingCost) && (
                    <div className="text-danger fs-6">{errors.loadingUploadingCost}</div>
                  )}
                  {values.loadingUploadingCost === 'shipowner' && (
                    <div className="mx-5 mt-2">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="loadingUploadingCostShipOwnerType"
                          id="lifo"
                          value="lifo"
                          onBlur={handleBlur}
                          checked={values.loadingUploadingCostShipOwnerType === 'lifo'}
                          onChange={handleChange}/>
                        <label className="form-check-label" htmlFor="lifo">LIFO</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="loadingUploadingCostShipOwnerType"
                          id="filo"
                          onBlur={handleBlur}
                          value="filo"
                          checked={values.loadingUploadingCostShipOwnerType === 'filo'}
                          onChange={handleChange}/>
                        <label className="form-check-label" htmlFor="filo">FILO</label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="loadingUploadingCostShipOwnerType"
                          id="lilo"
                          onBlur={handleBlur}
                          value="lilo"
                          checked={values.loadingUploadingCostShipOwnerType === 'lilo'}
                          onChange={handleChange}/>
                        <label className="form-check-label" htmlFor="lilo">LILO</label>
                      </div>
                      {(errors.loadingUploadingCostShipOwnerType && touched.loadingUploadingCostShipOwnerType) && (
                        <div className="text-danger fs-6">{errors.loadingUploadingCostShipOwnerType}</div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="form-label">Нотис судовладельцев</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      name="notice"
                      type="checkbox"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="3"
                      id="notice3days"/>
                    <label className="form-check-label" htmlFor="notice3days">за 3 суток</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      name="notice"
                      type="checkbox"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="5"
                      id="notice5days"/>
                    <label className="form-check-label" htmlFor="notice5days">за 5 суток</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      name="notice"
                      type="checkbox"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value="10"
                      id="notice10days"/>
                    <label className="form-check-label" htmlFor="notice10days">за 10 суток</label>
                  </div>
                  {(errors.notice && touched.notice) && (
                    <div className="text-danger fs-6">{errors.notice}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="form-label">Девиация</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="deviation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="deviationYes"
                      value="1"
                      checked={values.deviation === '1'}/>
                    <label className="form-check-label" htmlFor="deviationYes">Да</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="deviation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="deviationNo"
                      value="0"
                      checked={values.deviation === '0'}/>
                    <label className="form-check-label" htmlFor="deviationNo">Нет</label>
                  </div>
                  {(errors.deviation && touched.deviation) && (
                    <div className="text-danger fs-6">{errors.deviation}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="form-label">Компенсация</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="compensation"
                      id="compensationYes"
                      onBlur={handleBlur}
                      value="1"
                      checked={values.compensation === '1'}
                      onChange={handleChange}/>
                    <label className="form-check-label" htmlFor="compensationYes">Да</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="compensation"
                      id="compensationNo"
                      onBlur={handleBlur}
                      value="0"
                      checked={values.compensation === '0'}
                      onChange={handleChange}/>
                    <label className="form-check-label" htmlFor="compensationNo">Нет</label>
                  </div>
                  {(errors.compensation && touched.compensation) && (
                    <div className="text-danger fs-6">{errors.compensation}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="form-label">Возможность перевозки контейнеров на палубе</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="deckTransportation"
                      id="deckTransportationYes"
                      value="1"
                      checked={values.deckTransportation === '1'}
                      onBlur={handleBlur}
                      onChange={handleChange}/>
                    <label className="form-check-label" htmlFor="deckTransportationYes">
                      Да {values.deckTransportation === '1' && (
                      <span className="fs-6">(Допустимо на риск грузовладельца)</span>)}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="deckTransportation"
                      id="deckTransportationNo"
                      value="0"
                      checked={values.deckTransportation === '0'}
                      onBlur={handleBlur}
                      onChange={handleChange}/>
                    <label className="form-check-label" htmlFor="deckTransportationNo">Нет</label>
                  </div>
                  {(errors.deckTransportation && touched.deckTransportation) && (
                    <div className="text-danger fs-6">{errors.deckTransportation}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="form-label">Возможность перевозки грузов сторонних грузовладельцев</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="thirdPartyCargo"
                      id="thirdPartyCargoYes"
                      value="MOLOO"
                      onBlur={handleBlur}
                      checked={values.thirdPartyCargo === 'MOLOO'}
                      onChange={handleChange}/>
                    <label className="form-check-label" htmlFor="thirdPartyCargoYes">More or Less Owner’s Option</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="thirdPartyCargo"
                      id="thirdPartyCargoNo"
                      value="MOLCHOPT"
                      onBlur={handleBlur}
                      checked={values.thirdPartyCargo === 'MOLCHOPT'}
                      onChange={handleChange}/>
                    <label className="form-check-label" htmlFor="thirdPartyCargoNo">More or Less Charterer’s Option</label>
                  </div>
                  {(errors.thirdPartyCargo && touched.thirdPartyCargo) && (
                    <div className="text-danger fs-6">{errors.thirdPartyCargo}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="form-label">Возможность перевозки нестандартизированных контейнеров по ISO</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="nonStandardizedContainer"
                      id="nonStandardizedContainerYes"
                      value="1"
                      checked={values.nonStandardizedContainer === '1'}
                      onBlur={handleBlur}
                      onChange={handleChange}/>
                    <label className="form-check-label" htmlFor="nonStandardizedContainerYes">
                      Да {values.nonStandardizedContainer === '1' && (
                      <span className="fs-6">(С обязательным возвратом судна в исходное состояние, в котором оно было взято в аренду)</span>
                    )}
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="nonStandardizedContainer"
                      id="nonStandardizedContainerNo"
                      value="0"
                      onBlur={handleBlur}
                      checked={values.nonStandardizedContainer === '0'}
                      onChange={handleChange}/>
                    <label className="form-check-label" htmlFor="nonStandardizedContainerNo">Нет</label>
                  </div>
                  {(errors.nonStandardizedContainer && touched.nonStandardizedContainer) && (
                    <div className="text-danger fs-6">{errors.nonStandardizedContainer}</div>
                  )}
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label htmlFor="portOption" className="form-label">Опцион портов</label>
                  <select
                    value={values.portOption}
                    name="portOption"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-select"
                    id="portOption">
                    <option disabled selected>Выберите опцион портов</option>
                    <option value="Direct port">Direct port</option>
                    <option value="Option of the port">Option of the port</option>
                    <option value="Range">Range</option>
                    <option value="Broad c/p">Broad c/p</option>
                  </select>
                </div>
                {(errors.portOption && touched.portOption) && (
                  <div className="text-danger fs-6">{errors.portOption}</div>
                )}
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="form-label">Дополнительное страхование контейнеров</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="insuranceCharterer"
                      checked={true}/>
                    <label className="form-check-label" htmlFor="insuranceCharterer">За счет фрахтователя</label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="form-label">&nbsp;</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="sealingContainers"
                      checked={true}/>
                    <label className="form-check-label" htmlFor="sealingContainers">Опломбирование контейнеров</label>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="mb-3">
                  <label className="form-label">Выявление повреждения погруженных на судно контейнеров</label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="expenseShipowner"
                      checked={true}/>
                    <label className="form-check-label" htmlFor="expenseShipowner">За счёт судовладельца</label>
                  </div>
                </div>
              </div>

              {(Object.keys(errors).length === 0 && Object.keys(touched).length > 0) && (
                <div className="col-12 mt-3 mb-5 text-center">
                  <button type="button" className="btn btn-success"
                          onClick={() => {
                            calculateFreightRate(values)
                            setIsDocumentReady(true)
                          }}>
                    Сформировать документ
                  </button>
                  {(isDocumentReady && dollarFreightRate && dollarBrokerageCommission && demurrageRate && freightPerTeu) && (
                    <PDFDownloadLink
                      className="d-block"
                      document={<PDFDocument
                        values={values}
                        freightRate={dollarFreightRate}
                        demurrageRate={demurrageRate}
                        brokerageCommission={dollarBrokerageCommission}
                        freightPerTeu={freightPerTeu}/>}
                      fileName="freightCounter">
                      {({loading}) => loading ? '...' : 'Скачать'}
                    </PDFDownloadLink>
                  )}
                </div>
              )}
            </div>
          )}
        </Formik>
      </div>
    </div>
  )
}

export default App
