import {StyleSheet, Document, Page, Text, Font, View} from '@react-pdf/renderer'
import {Table, TR, TD} from '@ag-media/react-pdf-table'
import PropTypes from 'prop-types'
import {vessels} from './App'
import RobotoRegular from './fonts/Roboto-Regular.ttf'
import RobotoBold from './fonts/Roboto-Bold.ttf'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoRegular,
      fontWeight: 'normal'
    },
    {
      src: RobotoBold,
      fontWeight: 'bold'
    }
  ]
});

const layTimeLabels = {
  a: 'Laytime for loading',
  b: 'Laytime for discharging',
  c: 'Total laytime for loading and discharging',
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontSize: 12,
    fontFamily: 'Roboto',
    fontWeight: 'normal',
    color: 'black',
  },
  header: {
    marginBottom: 20,
    marginTop: 25,
    textAlign: 'center',
  },
  thirdPartHeader: {
    textAlign: 'center',
    marginBottom: 10,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  paragraph: {
    marginBottom: 10,
    alignContent: 'stretch'
  },
  paragraphName: {
    textAlign: 'center',
    marginBottom: 5,
    marginTop: 5,
    fontWeight: 'bold',
  },
  tdStyle: {
    paddingTop: '10px',
    paddingBottom: '10px',
    fontSize: 11
  },
  tdText: {
    paddingLeft: '10px',
    paddingRight: '10px'
  },
})

const PDFDocument = ({values, freightRate, brokerageCommission, demurrageRate, freightPerTeu}) => {
  const vessel = vessels[values.vesselId - 1]
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={{marginBottom: 20, marginTop: 25, textAlign: 'center', fontWeight: 'bold'}}>
          Voyage Charter-Party for Container Vessels
        </Text>
        <Text style={styles.header}>PART I</Text>
        <Table tdStyle={styles.tdStyle}>
          <TR>
            <TD>
              <Text style={styles.tdText}>1. Shipbroker<Br/>{values.shipbroker}</Text>
            </TD>
            <TD>
              <Text style={styles.tdText}>2. Place and Date<Br/>{values.placeAndDate}</Text>
            </TD>
          </TR>
          <TR>
            <TD>
              <Text style={styles.tdText}>3. Owners & Place of Business, Telephone, Telex and Telefax Number<Br/>{values.ownersAndPlace}</Text>
            </TD>
            <TD>
              <Text style={styles.tdText}>4. Charterers & Place of Business, Telephone, Telex and Telefax Number<Br/>{values.charterersAndPlace}</Text>
            </TD>
          </TR>
          <TR>
            <TD>
              <Text style={styles.tdText}>5. Vessel’s name<Br/>{vessel.name}</Text>
            </TD>
            <TD>
              <Text style={styles.tdText}>6 GRT/NRT<Br/>{vessel.grt}/{vessel.nrt}</Text>
            </TD>
          </TR>
          <TR>
            <TD style={{padding: 0}}>
              <Table tdStyle={styles.tdStyle}>
                <TR>
                  <TD>
                    <Text style={styles.tdText}>7. DWT on Summer Freeboard s (abt.)<Br/>{vessel.dwt}</Text>
                  </TD>
                </TR>
                <TR>
                  <TD>
                    <Text style={styles.tdText}>9. Class<Br/>{vessel.class}</Text>
                  </TD>
                </TR>
              </Table>
            </TD>
            <TD>
              <Text style={styles.tdText}>8. TEU Capacity (Maximum)<Br/>{vessel.teu}</Text>
            </TD>
          </TR>
          <TR>
            <TD>
              <Text style={styles.tdText}>10. Service Speed<Br/>{vessel.speed}</Text>
            </TD>
            <TD>
              <Text style={styles.tdText}>11. Fuel Consumption<Br/>{vessel.fuelConsumption}</Text>
            </TD>
          </TR>
          <TR>
            <TD>
              <Text style={styles.tdText}>12. Trading Limits<Br/>Japanese Sea</Text>
            </TD>
            <TD>
              <Text style={styles.tdText}>13. Insured value of vessel<Br/>{numberWithSpaces(values.vesselInsuranceCost)} $</Text>
            </TD>
          </TR>
          <TR>
            <TD>
              <Text style={styles.tdText}>14. Place of delivery<Br/>{values.deliveryPlace}</Text>
            </TD>
            <TD>
              <Text style={styles.tdText}>15. Place of redelivery<Br/>{values.returnPlace}</Text>
            </TD>
          </TR>
          <TR>
            <TD>
              <Text style={styles.tdText}>16. Name of owner’s P&I club<Br/>{values.insuranceShipownersClubName}</Text>
            </TD>
            <TD>
              <Text style={styles.tdText}>17. ⁠Name of Charterer’s P&I club<Br/>{values.insuranceCharterersClubName}</Text>
            </TD>
          </TR>
          <TR>
            <TD>
              <Text style={styles.tdText}>18. Freight rate<Br/>{numberWithSpaces(freightRate)} $</Text>
            </TD>
            <TD>
              <Text style={styles.tdText}>19. Freight per TEU<Br/>{numberWithSpaces(freightPerTeu)} $</Text>
            </TD>
          </TR>
          <TR>
            <TD style={{padding: 0}}>
              <Table tdStyle={styles.tdStyle}>
                <TR>
                  <TD>
                    <Text style={styles.tdText}>
                      20. Freight payment<Br/>
                      {values.paymentType === 'cash' ? 'Before voyage' : 'After voyage'}, {values.prepaymentType === '1' ? `advance payment ${values.prepaymentAmount}% included` : 'advance payment not included'}
                    </Text>
                  </TD>
                </TR>
                <TR>
                  <TD>
                    <Text style={styles.tdText}>21. Port of Loading<Br/>{values.loadingPort}</Text>
                  </TD>
                </TR>
                <TR>
                  <TD>
                    <Text style={styles.tdText}>
                      22. Laydays<Br/>{(new Date(values.layDayTime)).toLocaleDateString('ru-RU')} {(new Date(values.layDayTime)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                    </Text>
                  </TD>
                </TR>
                <TR>
                  <TD>
                    <Text style={styles.tdText}>
                      24. Cancelling<Br/>{(new Date(values.cancellingDate)).toLocaleDateString('ru-RU')} {(new Date(values.cancellingDate)).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'})}
                    </Text>
                  </TD>
                </TR>
                <TR>
                  <TD>
                    <Text style={styles.tdText}>25. Agents (Port of loading)<Br/>{values.agentsOfLoading}</Text>
                  </TD>
                </TR>
              </Table>
            </TD>
            <TD>
              <Text style={styles.tdText}>23. Laytime<Br/>{values.layTime}, {layTimeLabels[values.layTimeType]} ({values.layTimeSubType.toUpperCase()})</Text>
            </TD>
          </TR>
          <TR>
            <TD>
              <Text style={styles.tdText}>26. Agents (Port of discharge)<Br/>{values.agentsOfDischarge}</Text>
            </TD>
            <TD>
              <Text style={styles.tdText}>27. Broker comission<Br/>{numberWithSpaces(brokerageCommission)} $</Text>
            </TD>
          </TR>
          <TR>
            <TD>
              <Text style={styles.tdText}>28. Port of Discharge<Br/>{values.portOfDischarge}</Text>
            </TD>
            <TD>
              <Text style={styles.tdText}>29. General Average to be adjusted at<Br/>{values.commonAccidentRules}</Text>
            </TD>
          </TR>
          <TR>
            <TD>
              <Text style={styles.tdText}>30. Law and Arbitration<Br/>{values.lawAndArbitration}</Text>
            </TD>
            <TD>
              <Text style={styles.tdText}>31. Additional clauses covering special provisions, if agreed<Br/>{values.additionalClauses}</Text>
            </TD>
          </TR>
          <TR>
            <TD>
              <Text style={{fontSize: '8px', ...styles.tdText}}>
                It is mutually agreed between the party mentioned in Box 3 (hereinafter referred to as «the
                Owners») and the Party mentioned in Box 4 (hereinafter referred to as «the Charterers») that
                this Contract shall be performed in accordance with the conditions contained in Part I including
                additional clauses, if any agreed and stated in Box 36, and Part Il as well as Parte III. In the
                event of a conflict of conditions, the provisions of Part I and Part III shall prevail over those of
                Part II to the extent of such conflict but no further.
              </Text>
            </TD>
          </TR>
          <TR>
            <TD>
              <Text style={{paddingBottom: 40, ...styles.tdText}}>Signature (Owners)</Text>
            </TD>
            <TD>
              <Text style={{paddingBottom: 40, ...styles.tdText}}>Signature (Charterers)</Text>
            </TD>
          </TR>
        </Table>
        <Text break style={styles.header}>PART II</Text>
        <Text style={styles.paragraph}>
          It is agreed on the date shown in Box 2 between the party named in Box 3 as
          the Owners of the Vessel named in Box 5 and the party named in Box 4 as
          the Charterers as follows:
        </Text>
        <Text style={styles.paragraphName}>Period of Charter Party and Delivery</Text>
        <Text style={styles.paragraph}>
          The Owners shall deliver the Vessel to the Charterers at the Place of Delivery as indicated in Box 15. <Br/>
          Unless agreed by the Charterers to the contrary, delivery shall take place no earlier than the time/ date as indicated in Box 16 and no later than the time/ date as indicated in Box 16.<Br/>
          If prior to delivery, the Owners give notice to the Charterers that the Vessel
          will not be ready for delivery by the time/ date as indicated in Box 16, the
          Charterers shall declare within 48 hours after receiving notice thereof from
          the Owners whether they cancel or will take delivery of the Vessel but
          without prejudice to the Charterers' right to claim proved damages.<Br/>
          If the Owners are unable to deliver the Vessel at the Place of Delivery as
          indicated in Box 15 or any reason beyond the control of the Owners, delivery shall take place at the nearest point to the nominated Place of Delivery to
          which the Vessel may safely and reasonably proceed.
        </Text>
        <Text style={styles.paragraph}>
          The Owners shall give notice of readiness to deliver to 1he Charterers and/or
          the Charterers' local agents when in position to come on hire.
        </Text>
        <Text style={styles.paragraph}>
          Delivery shall be effected at any time, day or night, Saturdays, Sundays
          and holidays included.<Br/>
          At the time of delivery the Vessel shall be clean and in all respects fit to
          receive goods and ISO standard containers.<Br/>
          The Charterers' acceptance of delivery of the Vessel shall not prejudice
          their rights against the Owners under this Charter Party.
        </Text>
        <Text style={styles.paragraphName}>Owners' Undertaking</Text>
        <Text style={styles.paragraph}>
          The Owners undertake that, at delivery, the Vessel shall be of the description set out in Parts I and Ill hereof.
        </Text>
        <Text style={styles.paragraphName}>Trading Limits</Text>
        <Text style={styles.paragraph}>
          The Vessel shall be employed in lawful trades within Institute Warranty Limits
          (IWL) and within the Trading Limits as indicated in Box 12 for the carriage of lawful goods between safe ports or places where she can safely lie
          always afloat. The Vessel shall not be obliged to force ice nor to follow ice-breakers.<Br/>
          The Owners warrant that, at the time of signing this Charter Party, the Vessel
          has not traded to any countries which would make the Vessel unacceptable
          for calls at ports within the Trading Limits as indicated in Box 22. The Charterers shall provide a list of such countries.
        </Text>
        <Text style={styles.paragraphName}>Permitted Cargoes</Text>
        <Text style={styles.paragraph}>
          Except as provided below, the Vessel shall be used exclusively for the carriage containerised goods in ISO standard containers complying with the International
          Convention for Safe Containers.
        </Text>
        <Text style={styles.paragraphName}>Owners` Obligations</Text>
        <Text style={styles.paragraph}>
          Except as provided elsewhere in this Charter Party, the Owners shall, at
          their expense, maintain the Vessel in the Class as indicated in Box 9, in a
          Thoroughly efficient state of hull and machinery and in every way fitted for
          the service throughout the currency of this Charter Party.<Br/>
          the owners shall, inter alia, provide and pay the cost of the following:
          Container Lashings:
          Crew Assistance with inter alia:-
          preparing the Vessel's cranes, derricks, winches and/or cargo handling gear for use, opening and closing any
          hatches (other than pontoon type hatches), ramps and other means of access to goods and containers, docking,
          undocking and shifting operations in port, bunkering, maintaining power during loading and discharging operations,
          instructing crane drivers and winchmen in use of Vessel's gear, supervising stevedores lashing and unlashing
          goods and containers and the regular checking of lashings at sea when weather conditions permit, monitoring,
          recording performances and, when available, supplying labour for the repairing of the ‘Charterers’ refrigeration machinery, weather permitting.<Br/>
          The above services shall be rendered by the crew if required, provided port
          and local labour regulations permit, and except when repairing the Charterers' refrigeration machinery
          any overtime incurred shall be for the account of the Owners.<Br/>
          (Documentation: Any documentation relating to the Vessel that may be re-
          quired to permit the Vessel to trade within the limits as indicated in Box 12,
          SP including, but not limited to, certificates of financial responsibility for oil pollution, provided such
          oil pollution certificates are obtainable from the Owners' P & I club or some other available source, valid international tonnage
          certificate, Suez and Panama tonnage certificates, valid certificate of registry and certificates relating to the strength and/or serviceability of the Vessel's gear.<Br/>
          Fumigation and/or deratisation: The provision of certificates thereof at the commencement of the
          Charter Party and the renewal thereof throughout the Charter party, except if this is required as a result
          of the Charterers’ containers carried under this charter party, in which case these expenses shall be for the account of the Charterers.
        </Text>
        <Text style={styles.paragraphName}>Charterers' Obligations</Text>
        <Text style={styles.paragraph}>
          Except as provided elsewhere in this Charter Party, the Charterers shall
          provide and pay the costs of the following throughout the currency of this
          Charter Party:
          Provision of Details: The provision of full and accurate details of goods
          and containers (including any documentation required at any ports of call),
          their weights and stowage positions to the Master as early as possible but
          not later than upon arrival at the port of loading, with regular updating
          thereof and the provision of a full and accurate plan of the stowage of all
          a goods and containers actually loaded prior to sailing.<Br/>Such details shall include:-
          gross weights of containers.<Br/>
          Instructions to the Master: The Master, although appointed by the Owners, shall at all times during the currency of this Charter Party be under the
          orders and directions of the Charterers as regards employment and agency.<Br/>The Charterers shall be obliged at all times to furnish the Master with full and timely instructions.<Br/>
          Stevedoring,
          Charterers' Lashings,
          Condition of Containers: The Charterers warrant that all containers carried pursuant to this Charter Party have been constructed to a design approved by a Classification Society and are properly maintained.<Br/>
          Stowage in Containers: The correct stowage and safe securing of all goods within containers (including securing to flat rack containers) to withstand the rigours of the voyage.<Br/>
          Stowage Planning,
          Operating Expenses: All port charges, light and canal dues, pilotage, towage, consular charges, and all other
          charges and expenses relating to the operation of the Vessel not otherwise provided for in this Charter Party,
          other than charges or expenses relating to the crew.<Br/>
          Agency Costs: All agency fees and expenses for normal ship's at all ports or places of call.<Br/>
          Damage to Vessel: Any damage to the Vessel or loss or damage to its
          "equipment caused by stevedores during the currency of this Charter Party shall be reported by the Master to the Charterers or their agents, in writing, within 24 hours of the occurrence or as soon as possible thereafter but latest when the loss or damage could have been discovered by the exercise of due diligence.
          <Br/><Br/>
          The Charterers shall pay for stevedore stevedore damage whether or not
          payment has been made by stevedores to the Charterers.<Br/>
          Damage for which the Charterers are responsible affecting seaworthiness,
          or the proper working of the Vessel and/or her equipment, shall be repaired
          without delay to the Vessel after each occurrence in the Charterers' lime
          and shall be paid for by the Charterers.<Br/> Other repairs to damage for which
          the Charterers are responsible shall also be carried out in the Charterers'
          lime but, if this Is not possible, such repairs shall be carried out whilst the
          Vessel is in drydock in the Owners' time provided this does not interfere with
          the Owners' repair work, or by the Vessel's crew at the Owners' convenience.<Br/>All costs of such repairs shall be for the Charterers' account.<Br/>
          Redelivery: Redelivery of the Vessel at the Place of Redelivery as indicated in Box 18, unless agreed by the Owners or provided elsewhere to the
          contrary, in the same condition to that pertaining when the Vessel was delivered, fair wear and tear excepted.
          <Br/><Br/>
          The Charterers shall give the Owners one month notice of expected
          date and Place of Redelivery, which advice shall be updated 10,5 and 2
          days prior to expected redelivery.<Br/>
          Al the time of redelivery the Vessel shall be clean and fit to load goods and
          ISO standard containers.<Br/>
          Additional Premiums: All additional premiums for hull and machinery,
          war risks, including blocking and trapping, or protection and indemnity insurance incurred by the Owners over and above the premiums payable by
          the Owners.<Br/>The Owners shall allow the Charterers to arrange these additional covers on their behalf if the Charterers so request and if the proposed
          insurers and terms are acceptable to the Owners.
        </Text>
        <Text style={styles.paragraphName}>Hire</Text>
        <Text style={styles.paragraph}>
          Payment: Payment of hire shall be made in cash in full and without discount, semi-monthly in advance. If hire or any instalment thereof is not paid
          as aforesaid, the Charterers shall pay interest at the rate of 0,1 per cent per
          day on the amount outstanding from and including the due date unfit the
          date of payment.<Br/>
          Default: In default of punctual and regular payment as herein specified,
          the Owners may require the Charterers to make payment of the amount due
          within 96 running hours of receipt of notification from the Owners, failing
          which the Owners will have the right to withdraw the Vessel without prejudice to any claim the Owners may have against the Charterers under this
          Charter Party.<Br/>Further, so long as the hire remains unpaid, the Owners shall
          be entitled to suspend the performance of any and all of their obligations
          hereunder and shall have no responsibility whatsoever for any consequences thereof in respect of which the Charterers hereby indemnify the
          Owners.<Br/>Hire shall continue to accrue and any extra expenses resulting
          from such suspension shall be for the Charterers' account.<Br/>
          (d) Redelivery Adjustment: Should the Vessel be on her voyage towards the
          Place of Redelivery at the time payment of hire becomes due, said payment
          shall be made for the estimated time necessary to complete the voyage, less
          disbursements made by the Charterers for the Owners' account, including
          the estimated value of bunker fuel on board at redelivery.<Br/>When the Vessel is
          redelivered to the Owners any difference shall be refunded to or paid by the
          Charterers as appropriate, but not later than three months after redelivery of
          the Vessel.
        </Text>
        <Text style={styles.paragraphName}>Loss of Vessel</Text>
        <Text style={styles.paragraph}>
          Should the Vessel be lost, or become a constructive total loss, hire shall
          cease at noon on the day of her loss or constructive total loss, and if mis-
          of sing, from noon on the date when last heard of, and any hire paid in advance
          ne and not earned shall be returned to the Charterers and payment of any hire
          due shall be suspended until the Vessel is reported safe.
        </Text>
        <Text style={styles.paragraphName}>Lashings and Stevedoring</Text>
        <Text style={styles.paragraph}>
          Owners' Lashings: The Owners shall supply and maintain in good working order throughout the currency of this Charter Party sufficient lashing
          and securing equipment to facilitate the proper lashing and securing in accordance with the plan supplied by the Owners of the maximum number of
          ISO standard containers which may be carried in accordance with the details provided in Part III hereto.
          <Br/><Br/>
          The Charterers shall provide and pay for the cost of all stevedoring op-
          rations during the currency of this Charter Party including, inter alia,
          receipt, loading, handling, stowing, lashing, securing, unsecuring, unlashing, unstowing, discharging,
          tallying and delivering of all uncontainerised goods and containers and shall be liable to the Owners for
          all loss or damage caused to the Vessel by the improper or careless performance of such operations.<Br/>
          The Master shall supervise stevedores undertaking the tasks
          to ensure that these are done correctly and to his satisfaction.<Br/>
          The Master shall ensure that all lashings are regularly checked whilst at sea, weather permitting.
        </Text>
        <Text style={styles.paragraphName}>Insurances</Text>
        <Text style={styles.paragraph}>
          Hull and Machinery: The Owners warrant that the Vessel is insured under
          institute Time Clauses or similar clauses for IWL trading against loss, damage and collision liabilities for the value which cover
          will be maintained throughout the currency of this Charter Party.<Br/>
          Upon 10 days notice to the Charterers, the Owners shall be entitled to effect any reasonable change to this value.<Br/>
          The Owners agree that their insured value for
          the purpose of this Clause shall represent the Charterers maximum liability
          to the Owners for damage to the Vessel including time spent on repairs.<Br/>
          (b) Protection and Indemnity (P & I): The Owners and the Charterers warrant
          that the Vessel is entered on full terms with their respective P & I Clubs that such entries will be
          maintained with all calls paid up to date throughout the currency of this Charter Party.<Br/>
          War Risks: The Owners warrant that the Vessel is insured against loss of
          the Vessel by War Risks for international trading excluding additional premium/ restricted/ prohibited areas,
          which cover will be maintained throughout the currency of this Charter Party.
        </Text>
        <Text style={styles.paragraphName}>Law and Arbitration</Text>
        <Text style={styles.paragraph}>
          London: This Charter Party shall be governed by English law and any dispute arising out of this Charter
          Party shall be one arbitrator being appointed by each party, in accordance with the
          Arbitration Acts 1950 and 1979 or any statutory modification or re-enactment thereof for the tome being in force.<Br/>
          On the receipt by one party of the nomination in writing of the other party's arbitrator,
          that party shall appoint their arbitrator within fourteen days, failing which the decision of the single.<Br/>
          Arbitrator appointed shall apply.<Br/>
          If two Arbitrators properly appointed shall
          not agree they shall appoint an umpire whose decision shall be final.
        </Text>
        <Text style={styles.paragraphName}>Ice Clause</Text>
        <Text style={styles.paragraph}>
          If the port of loading becomes inaccessible due to ice conditions at the moment when the ship is ready to
          leave the last previous port, during the sea passage or upon arrival at this port, the shipowner,
          due to the danger of freezing into ice, has the right to cancelling the charter.
          <Br/><Br/>
          If the danger of freezing of the vessel has arisen in the course of loading, the master has the right to
          take the vessel out of the port with the amount of cargo on board and load the vessel at any port in the
          interests of the shipowner at any port of destination.
          <Br/><Br/>
          If ice prevents the ship from reaching the port of discharge, the Charterer has the right either to detain
          the ship until navigation resumes, paying demurrage, or to direct the ship to another safe and immediately
          accessible port where she can unload.<Br/>
          The appropriate direction must be given within 48 hours after the shipowner has notified the charterer of
          the inability to reach the original port of destination.<Br/>
          If during the discharge process, due to the danger of freezing into the ice, the master finds it necessary to
          leave port, he is entitled to do so and discharge the remaining cargo at the nearest safe port.<Br/>
          If the distance to the safe port exceeds 100 miles, the freight for cargo delivered to that port shall be proportionately increased.
        </Text>
        <View wrap={false}>
          <Text style={styles.header}>PART III</Text>
          <Text style={styles.thirdPartHeader}>Vessel`s Specification</Text>
          <Text>Built: {vessel.year}</Text>
          <Text>Flag: {vessel.flag}</Text>
          <Text>Speed: {vessel.speed}</Text>
          <Text>DWT at Summer Draft: {vessel.dwt}</Text>
          <Text style={styles.thirdPartHeader}>Main Dimensions</Text>
          <Text>Length overall: {vessel.length}</Text>
          <Text>Breadth: {vessel.breadth}</Text>
          <Text>Draught: {vessel.draught}</Text>
          <Text style={styles.thirdPartHeader}>Tonnage</Text>
          <Text>GRT: {vessel.grt}</Text>
          <Text>NRT: {vessel.nrt}</Text>
          <Text>Container Capacity TEU (20`x8`x8`6``): {vessel.teu}</Text>
        </View>
        <View wrap={false}>
          <Text style={styles.header}>Addendum</Text>
          <Table tdStyle={styles.tdStyle}>
            <TR>
              <TD>
                <Text style={styles.tdText}>
                  1. Cost of loading and unloading operations<Br/>
                  {values.loadingUploadingCost === 'charterer' ? 'Charterer’s payment' : 'Ship’s owner’s payment'} {values.loadingUploadingCost === 'shipowner' && `(${values.loadingUploadingCostShipOwnerType.toUpperCase()})`}
                </Text>
              </TD>
              <TD>
                <Text style={styles.tdText}>2. Deviation<Br/>{values.deviation === '1' ? 'Yes' : 'No'}</Text>
              </TD>
            </TR>
            <TR>
              <TD>
                <Text style={styles.tdText}>3. Possibility of the shipping containers on the deck<Br/>{values.deckTransportation === '1' ? 'Yes' : 'No'}</Text>
              </TD>
              <TD>
                <Text style={styles.tdText}>4. Possibility of the shipping non-standardised ISO containers<Br/>{values.nonStandardizedContainer === '1' ? 'Yes' : 'No'}</Text>
              </TD>
            </TR>
            <TR>
              <TD>
                <Text style={styles.tdText}>5. Shipowner’s notice<Br/>{values.notice.join(', ')} days</Text>
              </TD>
              <TD>
                <Text style={styles.tdText}>6. Indemnity<Br/>{values.compensation === '1' ? 'Yes' : 'No'}</Text>
              </TD>
            </TR>
            <TR>
              <TD>
                <Text style={styles.tdText}>7. Possibility of the shipping containers another owners<Br/>{values.thirdPartyCargo}</Text>
              </TD>
              <TD>
                <Text style={styles.tdText}>8. Port’s option<Br/>{values.portOption}</Text>
              </TD>
            </TR>
            <TR>
              <TD>
                <Text style={styles.tdText}>9. Demurrage rate<Br/>{numberWithSpaces(demurrageRate)} $</Text>
              </TD>
            </TR>
            <TR>
              <TD>
                <Text style={styles.tdText}>10. Additional container insurance</Text>
              </TD>
              <TD>
                <Text style={styles.tdText}>At the expense of the charterer</Text>
              </TD>
            </TR>
            <TR>
              <TD>
                <Text style={styles.tdText}>11. Sealing containers</Text>
              </TD>
              <TD>
                <Text style={styles.tdText}>
                  Containers to be sealed must be technically sound and have devices for attaching seals.<Br/>
                  Once containers are sealed, it must be impossible to access them without removing and damaging the seals attached.<Br/>
                  For each container, at least one seal is placed on the locking device of the working door, which is closed last.<Br/>
                  Seals affixed to containers must have the following signs: the name of the shipper or his forwarder; control signs.
                </Text>
              </TD>
            </TR>
            <TR>
              <TD>
                <Text style={styles.tdText}>12. Damage to containers</Text>
              </TD>
              <TD>
                <Text style={styles.tdText}>
                  If the container is damaged, a technical inspection report of the container is drawn up in triplicate describing the nature and extent of the damage
                </Text>
              </TD>
            </TR>
          </Table>
        </View>

        <Text style={styles.pageNumber} fixed render={({pageNumber, totalPages}) => `${pageNumber} / ${totalPages}`}/>
      </Page>
    </Document>
  )
}

export default PDFDocument

const Br = () => "\n";

const numberWithSpaces = x => {
  let parts = x.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return parts.join('.')
}

PDFDocument.propTypes = {
  values: PropTypes.object.isRequired,
  freightRate: PropTypes.number.isRequired,
  brokerageCommission: PropTypes.number.isRequired,
  demurrageRate: PropTypes.number.isRequired,
  freightPerTeu: PropTypes.number.isRequired,
}